import React from "react";
import PropTypes from "prop-types";
import Container from '@mui/material/Container';
// import AnimateScrollIntoViewportLazy from "../../_shared/AnimateScrollIntoViewportLazy";
import AnimacieTitle from "./AnimacieTitle";

const FullUp = (props) => {

    const { title, breakpoint } = props

    return (
        <Container
            maxWidth={false}
            className="cont contUp"
            key="conttitle"
            sx={{
                position: "relative",
                pt: 0, // [2, 4, 6, 8],
                pb: 0,
                px: [ 0.5, 1, 2, 3 ],
                transformStyle: "preserve-3d",
                mb: "3rem",
            }}
        >
            <div
                className="wrapTitleBoost"
                style={{
                    width: "100%",
                    perspective: "1000px",
                }}
            >
                {/* <AnimateScrollIntoViewportLazy
                    name={`animTitle-${breakpoint}`}
                    key={`animTitle-${breakpoint}`}
                >
                </AnimateScrollIntoViewportLazy> */}
                <AnimacieTitle title={title} breakpoint={breakpoint} />
            </div>
        </Container>

    );
};

FullUp.propTypes = {
    children: PropTypes.node,
};

export default FullUp;
